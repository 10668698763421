import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default function initHowItWorks() {
	const howItWorks = document.querySelector('#how-it-works');

	if(!howItWorks) return;
  if(window.matchMedia('(prefers-reduced-motion: reduce)').matches) return

	let tl = null;
	const increase = 0.3;
	const duration = 1.0;
	let tlStartFrame = .2;

	function animate() {
		const el = "#how-it-works";
		tl = gsap.timeline({
			defaults: {
				ease: 'power2.inOut',
			}
		});

		tl.to(el +' #satelitte', { duration: duration, opacity: 1});
		tl.to(el +' #line1', { duration: duration, opacity: 1}, tlStartFrame);
		tl.to(el +' #line9', { duration: duration, opacity: 1}, tlStartFrame);
		tl.to(el +' #s-band', { duration: duration, opacity: 1}, (tlStartFrame+=increase));
		tl.to(el +' #ku-band', { duration: duration, opacity: 1}, tlStartFrame);
		tl.to(el +' #line2', { duration: duration, opacity: 1}, (tlStartFrame+=increase));
		tl.to(el +' #line8', { duration: duration, opacity: 1}, tlStartFrame);
		tl.to(el +' #smartphone', { duration: duration, opacity: 1}, (tlStartFrame+=increase));
		tl.to(el +' #gateway', { duration: duration, opacity: 1}, tlStartFrame);
		tl.to(el +' #line3', { duration: duration, opacity: 1}, (tlStartFrame+=increase));
		tl.to(el +' #line7', { duration: duration, opacity: 1}, tlStartFrame);
		tl.to(el +' #mno-bands', { duration: duration, opacity: 1}, (tlStartFrame+=increase));
		tl.to(el +' #virtual-network', { duration: duration, opacity: 1}, tlStartFrame);
		tl.to(el +' #line4', { duration: duration, opacity: 1}, (tlStartFrame+=increase));
		tl.to(el +' #line6', { duration: duration, opacity: 1}, tlStartFrame);
		tl.to(el +' #tower', { duration: duration, opacity: 1}, (tlStartFrame+=increase));
		tl.to(el +' #mobile-network', { duration: duration, opacity: 1}, tlStartFrame);
		tl.to(el +' #line5', { duration: duration, opacity: 1}, (tlStartFrame+=increase));

		// tl.start();

	}


	ScrollTrigger.create({
		trigger: howItWorks,
		start: "top center",
		end: "20% center",
		pin: false,

		onToggle: (self) => {
			if (self.isActive) {
				if(!tl) {
					animate();
				}

				console.log('scrolltrigger active', tl);
			}
		},

		onUpdate: (self) => {
			  console.log(
				"progress:",
				(self.progress*100).toFixed(0),
				"direction:",
				self.direction,
				"velocity",
				self.getVelocity(),
				"active",
				self.isActive
			  );
			if(!tl && !self.isActive && self.progress > .9) {
				console.log('scrolltrigger inactive', tl);
				animate();
			}
		},
	});


}
