import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { getBlockOrder } from '../../utils/gsap';

/**
 * Creates a stacked cards effect with GSAP animations.
 */
export function cardsStack() {
  gsap.registerPlugin(ScrollTrigger);

  const blocks = document.querySelectorAll('[data-cards-stack]');
  const mediaQueryMobile = window.matchMedia('(max-width: 1023px)');
  const mediaQueryDesktop = window.matchMedia('(min-width: 1024px)');
  const mm = gsap.matchMedia();

  blocks.forEach((block, index) => {
    const refreshPriorityOrder = getBlockOrder(block);
    const items = block.querySelectorAll('[data-cards-stack-item] > *');
    const scrollTriggerId = `cardsStack-${index}`;

    let timeline = null;

    if(window.matchMedia('(prefers-reduced-motion: reduce)').matches) return;

    // If there are less than two items, exit
    if (items.length < 2) return;

    function destroy() {
      if (timeline) {
        timeline.kill(true);
        timeline = null;

        if (typeof ScrollTrigger.getById(scrollTriggerId) != 'undefined') {
          ScrollTrigger.getById(scrollTriggerId).kill(true);
        }
        gsap.set(block, { clearProps: true });
      }
    }

    // init
    // --------------------------------------------
    items.forEach((item, index) => {
      gsap.set(item, {
        zIndex: index * 1 + 1,
        position: 'relative'
      });
    });

    // Init Mobile
    // --------------------------------------------
    mm.add(mediaQueryMobile, () => {
      destroy();
    });

    // Init Desktop
    // --------------------------------------------
    mm.add(mediaQueryDesktop, () => {
      /**
       * Creates and manages the timeline animation.
       */
      function createTimeline() {
        timeline = gsap.timeline({
          scrollTrigger: {
            id: scrollTriggerId,
            trigger: block,
            pin: block,
            scrub: 1,
            start: 'top top',
            end: `+=${document.documentElement.offsetHeight * 0.1}`,
            refreshPriority: refreshPriorityOrder,
            invalidateOnRefresh: true,
            markers: false
          }
        });

        timeline
          .fromTo(items[0], { scale: 1 }, { scale: 0.8 })
          .fromTo(items[1],
          {
            scale: 0.8,
            y: () => {
              return items[0].offsetHeight;
            }
          },
          {
            scale: 1,
            yPercent: -90
          },
          '<');
      }

      // init
      // --------------------------------------------
      destroy();

      gsap.set(block, {
        height: () => {
          return items[0].offsetHeight + items[1].offsetHeight - (items[1].offsetHeight * 0.9);
        },
        onComplete() {
         createTimeline();
        }
      });

    });
  });
}
