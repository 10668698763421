import Swiper from 'swiper';
import { Navigation, A11y } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/a11y';

import { getA11y } from '../../utils/swiper';

export function articlesSlider() {
  const pageLang = document.querySelector("html").getAttribute("lang") || "en";
  const sliders = document.querySelectorAll('.swiper--articles');
  const isScreenDesktop = window.matchMedia('(min-width:1024px)');

  let currentViewport = null;
  let lastViewport = null;


  let sliderInstances = [];

  if (!sliders) return;

  function onChange() {
    if (isScreenDesktop.matches) {
      currentViewport = 'desktop';
      sliderInstances.forEach((slider) => {
        slider.enable();
      });
    } else {
      currentViewport = 'mobile';
      sliderInstances.forEach((slider) => {
        slider.disable();
      });
    }

    lastViewport = currentViewport;
  }

  // init
  // --------------------------------------------
  sliders.forEach((slider) => {
    const sliderSwiper = new Swiper(slider, {
      a11y: getA11y(pageLang),
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 0,
      centeredSlides: false,
      watchSlidesProgress: true,
      navigation: {
        nextEl: '.swiper-button-next-custom',
        prevEl: '.swiper-button-prev-custom'
      },
      breakpoints: {
        1024: {
          enabled: true,
          spaceBetween: 32
        },
        1400: {
          spaceBetween: 64
        }
      },
      modules: [Navigation, A11y],
    });
    sliderInstances.push(sliderSwiper);
  });

  isScreenDesktop.addEventListener('change', () => {
    onChange();
  });
  onChange();
}
