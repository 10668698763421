export default function expandableBody() {
	const expandableBodys = document.querySelectorAll('.expandable-body');

	if (!expandableBodys.length) return;

	expandableBodys.forEach((expandableBody) => {
		const expandableBodyTrigger = expandableBody.querySelector('.expandable-body__trigger');
		const expandableBodyTriggerNotActive = expandableBody.querySelector('.expandable-body__trigger--not-active');
		const expandableBodyTriggerActive = expandableBody.querySelector('.expandable-body__trigger--active');
		const expandableBodyContent = expandableBody.querySelector('.expandable-body__content');
		const inner = expandableBodyContent.querySelector('.expandable-body__content-inner');

		expandableBodyTrigger.addEventListener('click', () => {
			expandableBodyTrigger.classList.toggle('active');
			expandableBodyContent.classList.toggle('active');
			expandableBodyTriggerNotActive.classList.toggle('active');
			expandableBodyTriggerActive.classList.toggle('active');

			if(expandableBodyContent.classList.contains('active')) {
				expandableBodyContent.style.height = inner.clientHeight+'px';
        expandableBodyContent.setAttribute('aria-hidden', 'false');
        expandableBodyTrigger.setAttribute('aria-expanded', 'true');
			} else {
				expandableBodyContent.style.height = 0;
        expandableBodyContent.setAttribute('aria-hidden', 'true');
        expandableBodyTrigger.setAttribute('aria-expanded', 'false');
			}
		});
	});
}
