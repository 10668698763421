import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export function footerRevealFullX() {
    gsap.registerPlugin(ScrollTrigger);

    const background = document.querySelector('[data-block-footer-background]');
    const borderRadius = getComputedStyle(document.documentElement).getPropertyValue('--site-borderRadius-block');
    const section = document.querySelector('[data-block-footer]');
    const hasBorderRadius = (section.getAttribute('data-block-footer-radius') === "true") ? true : false;

    if(window.matchMedia('(prefers-reduced-motion: reduce)').matches) return;
    if (!background) return;

    const tlUp = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top-=100% 40%",
        end: "bottom-=50% bottom",
        scrub: 1,
        invalidateOnRefresh: true,
        onRefresh: () => {
          tlUp.scrollTrigger.refresh();
        },
        // markers: {
        //   startColor: 'green',
        //   endColor: 'red',
        //   fontSize: '24px',
        //   fontWeight: 'bold',
        //   indent: 100
        // }
      }
    });

    tlUp.fromTo(
      background,
      {
        borderRadius: hasBorderRadius ? borderRadius : 0,
        scaleX: 1
      },
      {
        borderRadius: 0,
        scaleX: () => document.documentElement.clientWidth / section.offsetWidth

      },
      0
    );
}
