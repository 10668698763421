import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export function heroImage() {
  gsap.registerPlugin(ScrollTrigger);

  const containerImage = document.querySelectorAll('.block-hero .block-hero__container-background');
  const mediaQueryMobile = window.matchMedia('(max-width: 1023px)');
  const mediaQueryDesktop = window.matchMedia('(min-width: 1024px)');
  const borderRadius = getComputedStyle(document.documentElement).getPropertyValue('--site-borderRadius-block');
  const isReduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

  let mm = gsap.matchMedia();

  if (containerImage.length === 0) {
    return;
  }

  containerImage.forEach((section) => {
    const video = section.querySelector('video');
    if (video && isReduceMotion) {
      video.pause();
      video.currentTime = 0;
    }
  });

  if (isReduceMotion) return;

  // Mobile - Tablet
  // --------------------------------------------
  containerImage.forEach((section) => {
    mm.add(mediaQueryMobile, () => {
      gsap.to(section, {
        borderRadius: 0,
        width: () => '100%',
        duration: 0.5,
        ease: 'power2.inOut'
      });
    });
  });

  // Desktop
  // --------------------------------------------
  containerImage.forEach((section) => {
    mm.add(mediaQueryDesktop, () => {
      gsap.fromTo(
        containerImage,
        {
          width: () => '100%',
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0
        },
        {
          width: () =>
            document.documentElement.clientWidth -
            getComputedStyle(document.documentElement).getPropertyValue('--site-container-margin'),
          borderBottomRightRadius: borderRadius,
          borderBottomLeftRadius: borderRadius,
          ease: 'power2.inOut',
          scrollTrigger: {
            trigger: section,
            start: 'center center',
            end: '75% center',
            scrub: 1,
            invalidateOnRefresh: true
          }
        }
      );
    });
  });
}
