import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export function bannerImageText() {
  gsap.registerPlugin(ScrollTrigger);

  let mm = gsap.matchMedia();

  const mediaQueryMobile = window.matchMedia('(max-width: 639px)');
  const mediaQueryTablet = window.matchMedia('(min-width: 640px) and (max-width: 1023px)');
  const mediaQueryDesktop = window.matchMedia('(min-width: 1024px)');
  const sections = document.querySelectorAll('[data-banner-image-text]');

  if(window.matchMedia('(prefers-reduced-motion: reduce)').matches) return;

  if (sections.length === 0) {
    return;
  }

  const borderRadius = getComputedStyle(document.documentElement).getPropertyValue('--site-borderRadius-block');
  const imageMaxWidth = getComputedStyle(document.documentElement).getPropertyValue('--site-image-scale-up-max');
  const constainerMaxWidth = getComputedStyle(document.documentElement).getPropertyValue('--site-container-max-width');
  const containerMarginX = 80;

  // Mobile
  // --------------------------------------------
  sections.forEach((section) => {
    mm.add(mediaQueryMobile, () => {
      gsap.to(section, {
        borderRadius: 0,
        duration: 0.5,
        ease: 'power2.inOut'
      });
    });
  });

  // Tablet
  // --------------------------------------------
  sections.forEach((section) => {
    mm.add(mediaQueryTablet, () => {
      gsap.to(section, {
        borderRadius: 0,
        duration: 0.5,
        ease: 'power2.inOut'
      });
    });
  });

  // Desktop
  // --------------------------------------------
  sections.forEach((section) => {
    mm.add(mediaQueryDesktop, () => {

    const image = section.querySelector('[data-banner-image-text-container-image]');
    const content = section.querySelector('[data-banner-image-text-content]');

    const timeline = gsap.timeline({
      defaults: { duration: 1, ease: 'power2.inOut' }
    });

    timeline.fromTo(
      image,
      {
        scale: () => {
          let scaleMax;

          if (document.documentElement.clientWidth <= constainerMaxWidth) {
            return (document.documentElement.clientWidth - containerMarginX) / document.documentElement.clientWidth;
          }

          scaleMax = Math.min(document.documentElement.clientWidth, imageMaxWidth);
          return constainerMaxWidth / scaleMax;
        },
        borderRadius: borderRadius
      },
      {
        scale: () => {
          let scaleMax = Math.min(document.documentElement.clientWidth, imageMaxWidth);
          return scaleMax / section.offsetWidth;
        },
        borderRadius: 0
      }
    ).fromTo(
      content,
      {
        opacity: 0,
        y: 200,
      },
      {
        opacity: 1,
        y: 0,
      },
      "<"
    );

      ScrollTrigger.create({
        trigger: section,
        start: 'top-=100px bottom',
        end: '25% center',
        scrub: 1,
        invalidateOnRefresh: true,
        animation: timeline,
        toggleActions: "play none none none",
        // markers: {
        //   startColor: "green",
        //   endColor: "red",
        //   fontSize: "18px",
        //   fontWeight: "bold",
        //   indent: 20
        // },
      });

    });
  });
}
