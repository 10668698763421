import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { getDeviceInfo } from '../../utils/device';
import { getBlockOrder } from '../../utils/gsap';


function removeActiveImages(blockId, currentId) {
	// console.log('removeActiveImages', blockId, currentId);
	const stickyBlockWrapper = document.querySelector('#'+blockId);
	const stickyBlockImages = stickyBlockWrapper.querySelectorAll('.sticky-block__image');
	stickyBlockImages.forEach((stickyBlock) => {
		const itemId = parseInt(stickyBlock.dataset.itemId);
		if(itemId > currentId) {
			stickyBlock.classList.remove('active');
		}
	});
}

export function initStickyBlock () {
	const stickyBlockWrapper = document.querySelectorAll('.sticky-block-wrapper');

	if (!stickyBlockWrapper.length) return;

	function removeStayActive(blockId, currentBlock) {
		// console.log('removeStayActive');
		const stickyBlockWrapper = document.querySelector('#'+blockId);

		const stickyBlocks = stickyBlockWrapper.querySelectorAll('.sticky-block__content');
		stickyBlocks.forEach((stickyBlock) => {

			const id = stickyBlock.dataset.itemId;

			if(stickyBlock.dataset.itemId != currentBlock) {
				stickyBlock.classList.remove('active');
			}
			if(id < currentBlock) {
				stickyBlock.classList.add('up');
			}
			if(id > currentBlock) {
				stickyBlock.classList.remove('up');
			}
		});
	}

	function updateArrowVisibility(currentBlock, counter, prev, next) {
		if(currentBlock == 1) {
			// console.log("currentBlock === 1");
			prev.classList.add('invisible','pointer-events-none');
		} else {
			prev.classList.remove('invisible','pointer-events-none');
		}
		if(currentBlock === counter.children.length) {
			next.classList.add('invisible','pointer-events-none');
		} else {
			next.classList.remove('invisible','pointer-events-none');
		}
	}

	function updateCounter(currentBlock, counter, prev, next) {
		const space = 42;
		const to = (currentBlock - 1) * space;
		gsap.to(counter, {y: -to, duration: .5});

		// console.log('updateCounter', currentBlock, counter.children.length);

		updateArrowVisibility(currentBlock, counter, prev, next);
	}

	stickyBlockWrapper.forEach((wrapper) => {
		const blockId = wrapper.id;
		const stickyBlocks = wrapper.querySelectorAll('.sticky-block');
		const counter = wrapper.querySelector('.sticky-block__counter');
		const prev = wrapper.querySelector('.sticky-block__prev');
		const next = wrapper.querySelector('.sticky-block__next');

		let currentBlock = 1;

		prev.addEventListener('click', () => {
			// console.log('prev');
			if(currentBlock > 1) {
				const block = wrapper.querySelector('.sticky-block[data-item-id="'+(currentBlock-1)+'"]');
				const elementPosition = block.getBoundingClientRect().top;
    			const offsetPosition = elementPosition - window.innerHeight + 100;

				window.scrollBy({
					top: offsetPosition,
					behavior: "smooth"
			   	});
				currentBlock--;
			}
		});
		next.addEventListener('click', () => {
			// console.log('next');
			if(currentBlock < stickyBlocks.length) {
				const block = wrapper.querySelector('.sticky-block[data-item-id="'+(parseInt(currentBlock)+1)+'"]');
				const elementPosition = block.getBoundingClientRect().top;
    			const offsetPosition = elementPosition - 170;
				// console.log('next', elementPosition, offsetPosition, block.getBoundingClientRect());
				window.scrollBy({
					top: offsetPosition,
					behavior: "smooth"
			   	});
				currentBlock++;
			}
		});

		stickyBlocks.forEach((stickyBlock) => {
			const refreshPriorityOrder = getBlockOrder(stickyBlock);
			ScrollTrigger.create({
				trigger: stickyBlock,
				start: "center top+=60%",
				pin: true,
				refreshPriority: refreshPriorityOrder,
				onToggle: (self) => {
					// console.log("toggled, isActive:", self.isActive, self.trigger.dataset.itemId)
					const id = self.trigger.dataset.itemId;
					const content = wrapper.querySelector('.sticky-block__content[data-item-id="'+id+'"]');
					const image = wrapper.querySelector('.sticky-block__image[data-item-id="'+id+'"]');
					if (self.isActive) {
						currentBlock = parseInt(id);
						removeStayActive(blockId, currentBlock);
						removeActiveImages(blockId, currentBlock);
						content.classList.add('active');
						content.classList.remove('up');
						image.classList.add('active');

						updateCounter(currentBlock, counter, prev, next);
					}
				},

				onUpdate: (self) => {
				//   console.log(
				// 	"progress:",
				// 	self.progress.toFixed(3),
				// 	"direction:",
				// 	self.direction,
				// 	"velocity",
				// 	self.getVelocity()
				//   );
				},
			  });
		});
		updateArrowVisibility(currentBlock, counter, prev, next);
	});
}

export function initStickyBlockMobile () {

	const stickyBlockWrapper = document.querySelectorAll('.sticky-block-mobile');

	if (!stickyBlockWrapper.length) return;
	const deviceinfo = getDeviceInfo();


	function onOrientationChange(wrapper) {
		if(typeof screen.orientation !== 'undefined' && screen.orientation.type.indexOf('landscape') > -1 && deviceinfo.isMobile) {
			wrapper.classList.add('mobile--landscape');
		} else {
			wrapper.classList.remove('mobile--landscape');
		}
	}

	stickyBlockWrapper.forEach((wrapper) => {
		const blockId = wrapper.id;
		// console.log("wrapper ", blockId);
		const items = wrapper.querySelectorAll('.sticky-block-mobile__item');
		const mask = wrapper.querySelector('.sticky-block-mobile__mask');
		const imageWrapper = wrapper.querySelector('.sticky-block-mobile__image-wrapper');
		let currentBlock = 1;
		let triggers = [];

		let br = imageWrapper.getBoundingClientRect();
		let brTop = br.top;

		if(brTop < 120) {
			currentBlock = items.length;
		}

		window.addEventListener('scroll', () => {
			br = imageWrapper.getBoundingClientRect();

			if(br.top == brTop && br.top < 125 || br.top < 125 && br.top > 0) {

				mask.classList.remove('hidden');
				// removeActiveImages(blockId, items.length);
				if(currentBlock === 4) {
					const image = wrapper.querySelector('.sticky-block__image[data-item-id="'+items.length+'"]');
					image.classList.add('active');
				}

			} else if(br.top > brTop) {
				mask.classList.add('hidden');
			}
			brTop = br.top;
			// console.log('br.top', br.top, mask.classList.contains('hidden'));
		});

		items.forEach((item) => {
			// console.log("item ", item);
			const trigger = ScrollTrigger.create({
				trigger: item,
				start: "top top+=70%",
				end: "center center",
				pin: false,
				onToggle: (self) => {
					const id = parseInt(self.trigger.dataset.itemId);
					const image = wrapper.querySelector('.sticky-block__image[data-item-id="'+id+'"]');
					if (self.isActive) {
						// console.log("toggled, isActive:", self.isActive, self.trigger.dataset.itemId)
						// if(currentBlock > id && id === 1 ) {
						// 	mask.classList.remove('hidden');
						// } else if(id === 1) {
						// 	mask.classList.remove('hidden');
						// } else if(currentBlock > 1 && self.direction === -1) {
						// 	mask.classList.remove('hidden');
						// }

						currentBlock = parseInt(id);
						removeActiveImages(blockId, currentBlock);
						image.classList.add('active');
					}
				},
				// markers: {
				// 	startColor: 'green',
				// 	endColor: 'yellow',
				// 	fontSize: '24px',
				// 	fontWeight: 'bold',
				// 	indent: 100
				//   },
				onUpdate: (self) => {
					// self.trigger.style.opacity = '0%';
					// if(self.progress > 0.80) {
					// 	self.trigger.style.opacity = '35%';
					// } else if(self.progress < 0.75) {
					// 	self.trigger.style.opacity = '100%';
					// }
					//   console.log(
					// 	"progress:",
					// 	(self.progress*100).toFixed(0),
					// 	"direction:",
					// 	self.direction,
					// 	"velocity",
					// 	self.getVelocity(),
					// 	"trigger",
					// 	self.trigger.dataset.itemId
					//   );
				},
			});
			triggers.push(trigger);

      if (typeof screen.orientation !== 'undefined') {
        screen.orientation.addEventListener('change', () => {
          // console.log('screen.orientation.type', screen.orientation.type, deviceinfo.isIOS, deviceinfo.isMobile, screen.orientation.type.indexOf('landscape') > -1);
          onOrientationChange(wrapper);

        });
      }
			onOrientationChange(wrapper);
		});

		window.addEventListener('scroll', () => {
			triggers.forEach((trigger) => {
				trigger.refresh();
			});
		});



	});
}
