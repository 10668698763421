export function getDeviceInfo() {

	const isIOS = /iPad|iPhone|iPod|Macintosh|Mac/.test(navigator.userAgent) && navigator.maxTouchPoints > 1;
	const isAndroid = /Android/.test(navigator.userAgent);
	const isMobile = navigator.maxTouchPoints > 1;

	return {
		isIOS,
		isAndroid,
		isMobile
	};
}