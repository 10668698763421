import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { getBlockOrder } from '../../utils/gsap';

export function columns2TextImagePin() {
  gsap.registerPlugin(ScrollTrigger);

  const sections = document.querySelectorAll('[data-columns2pin]');

  if (!sections.length) return;

  sections.forEach(createSectionAnimation);
}

function createSectionAnimation(section) {
  const content = section.querySelector('[data-columns2pin-columnContent]');
  const contentItems = Array.from(content.querySelectorAll('[data-columns2pin-columnContent-item]'));
  const images = Array.from(section.querySelectorAll('[data-columns2pin-columnImage-item]'));

  const refreshPriority = getBlockOrder(section);

  const duration = window.matchMedia('(prefers-reduced-motion: reduce)').matches ? 0 : 0.5;

  ScrollTrigger.create({
    trigger: section,
    pin: content,
    pinSpacing: 0,
    start: 'top top',
    end: 'bottom bottom',
    refreshPriority,
  });

  images.forEach((image, index) => {
    gsap.set(contentItems[index], { opacity: 0 });

    ScrollTrigger.create({
      trigger: image,
      start: '0% 50%',
      end: '100% 50%',
      scrub: true,
      invalidateOnRefresh: true,
      refreshPriority: refreshPriority - index,
      onEnter: () => gsap.to(contentItems[index], { opacity: 1, duration }),
      onEnterBack: () => gsap.to(contentItems[index], { opacity: 1, duration }),
      onLeave: () => gsap.to(contentItems[index], { opacity: 0, duration}),
      onLeaveBack: () => gsap.to(contentItems[index], { opacity: 0, duration }),
    });
  });
}


