import LazyLoad from 'vanilla-lazyload';

import '/assets/styles/main.pcss';

import { blockRevealFullX } from './components/animations/blockRevealFullX';
import initHowItWorks from './components/animations/howItWorks';
import { imageMaskTranslateX } from './components/animations/imageMaskTranslateX';

import { articlesSlider } from './components/sliders/articles';
import { imageSimpleSlider } from './components/sliders/imageSimple';


import { bannerImageText } from './components/blocks/bannerImageText';
import { columns2TextImagePin } from './components/blocks/columns2TextImagePin';
import { footerRevealFullX } from './components/blocks/footerRevealFullX';
import { headerNavReveal } from './components/blocks/headerNavReveal';
import { headerThemeColor } from './components/blocks/headerThemeColor';
import { heroImage } from './components/blocks/heroImage';
import { highlightCategories } from './components/blocks/highlightCategories';
import { imageFullWidthWithBox } from './components/blocks/imageFullWidthWithBox';
import { cardsStack } from './components/blocks/cardsStack';
import { siteMobileNav } from './components/blocks/siteMobileNav';
import { initStickyBlock, initStickyBlockMobile } from './components/blocks/stickyBlock';
import { videoBlockToFullScreen } from './components/blocks/videoBlockToFullScreen';

import expandableBody from './components/expandableBody';
import initTeams from './components/teams';
import initTimeline from './components/timeline';
import initMap from './components/whatWeDoMap';
import jobForm from './components/forms/jobForm';
import partnerForm from './components/forms/partnerForm';
import accessibilityForm from './components/forms/accessibilityForm';

import { setExternalLink } from './utils/url';
import { initGtmCta } from './utils/gtm';
import { scrollToAnchor } from './utils/scrollToAnchor';


window.lazyloadInstance = new LazyLoad();

document.addEventListener('DOMContentLoaded', (event) => {
  console.log('Page DOMContentLoaded');
  imageMaskTranslateX();
  blockRevealFullX();
  siteMobileNav();
  articlesSlider();
  imageSimpleSlider();
  setExternalLink();
});

window.addEventListener('load', (event) => {
  console.log('Page load');
  initMap();
  heroImage();
  footerRevealFullX();
  initTimeline();
  initTeams();
  initStickyBlock();
  initStickyBlockMobile();
  videoBlockToFullScreen();
  initHowItWorks();
  bannerImageText();
  expandableBody();
  imageFullWidthWithBox();

  cardsStack();
  columns2TextImagePin();
  highlightCategories();
  jobForm();
  headerThemeColor();
  headerNavReveal();
  partnerForm();
  accessibilityForm();

  setTimeout(() => {
    scrollToAnchor();
  }, 1);

  initGtmCta();
});
