export function headerThemeColor() {

  const siteHeader = document.getElementById('site-header');
  const sections = document.querySelectorAll('[data-section-theme]');

  if(!siteHeader) {
    return;
  }

  function setTheme(theme) {
    siteHeader.setAttribute('data-theme', theme);
  }

  function handleScroll() {
    sections.forEach((section) => {
      const br = section.getBoundingClientRect();
      const theme = section.getAttribute('data-section-theme');
      if(br.top + br.height > 50 && br.top < 50) {
        setTheme(theme);
      }
    });
  }

  document.addEventListener('scroll', (e) => {
    handleScroll();
  });
  handleScroll();


}
