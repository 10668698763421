export default function initTeams() {
	const teams = document.querySelector('#teams');
	if (!teams) return;

	const items = teams.querySelectorAll('.team-item');
	const teamsWrapper = teams.querySelector('#teams-wrapper');
	const teamsItems = teams.querySelectorAll('.team-item[data-item-id="photos"]');
	const overlayBg = teamsWrapper.querySelector('.overlay-bg');
	const teamDescriptionWrapper = document.querySelector('.team-description-wrapper');
	let activeItem = null;

	function resetActive() {

		if(!activeItem) return;

		activeItem.classList.remove('active');
		teamsWrapper.classList.remove('active');
		hideDescription();
		// remove inline styles
		// const img = activeItem.querySelector('.img-wrapper');
		// img.style.minWidth = `${minWidth}px`;
		// img.style.width = `${minWidth}px`;
	}

	function showDescription(id) {
		const descriptions = teams.querySelectorAll('.team-description');
		descriptions.forEach((description) => {
			description.classList.remove('active');
      description.setAttribute('aria-hidden', 'true');
		});
		const description = teams.querySelector('.team-description[data-item-id="'+id+'"]');

		description.classList.add('active');
    description.setAttribute('aria-hidden', 'false');
		teamsWrapper.classList.add('active');
	}

	function hideDescription() {
		const descriptions = teams.querySelectorAll('.team-description');
		descriptions.forEach((description) => {
			description.classList.remove('active');
      description.setAttribute('aria-hidden', 'true');
		});
		teamsWrapper.classList.remove('active');
	}

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  function handleFocusOut(trigger) {
    resetActive();
    trigger.focus();
  }

  function handleEscapeKey(trigger) {
    resetActive();
    trigger.focus();
  }

	function init() {
		// const wrapperWidth = teamsWrapper.clientWidth-gap;

		// width = (wrapperWidth/4)*1.2;
		// minWidth = (wrapperWidth-width)/3;

		overlayBg.addEventListener('click', () => {
			resetActive();
		});


		items.forEach((item, index) => {

			// const img = item.querySelector('.img-wrapper');

			// if(!img) return;

			// if(index === 0) {
			// 	// width = img.clientWidth;
			// 	// item.classList.add('active');
			// 	img.style.minWidth = `${width}px`;
			// 	img.style.width = `${width}px`;
			// 	activeItem = item;
			// 	const id = item.dataset.itemId;
			// 	showDescription(id);
			// } else {
				// minWidth = img.clientWidth;
      // img.style.minWidth = `${minWidth}px`;
      // img.style.width = `${minWidth}px`;
			// }

			item.addEventListener('click', () => {
				resetActive();
				const id = item.dataset.itemId;
				if(id == 'photos') {
					const featured = teams.querySelector('.photo-item-center');
					const otherImages = teams.querySelectorAll('.photo-item');
					const bg = item.children[0].dataset.bg;

					const photoId = item.dataset.photoId;
					let arr = [];
					teamsItems.forEach((i) => {
						const pid = i.dataset.photoId;
						if(pid !== photoId) {
							arr.push(i.children[0].dataset.bg);
						}
					});

          shuffle(arr);

					otherImages.forEach((el, index) => {
						el.style.backgroundImage = `url(${arr[index]})`;
					});

					featured.style.backgroundImage = `url(${bg})`;
				}


				showDescription(id);
				item.classList.add('active');
				teamDescriptionWrapper.classList.add('active');
				const info = teams.querySelector('.team-description[data-item-id="'+id+'"]');
				const closeModalBtn = info.querySelector('.close-modal-btn');
        const trigger = item;

        closeModalBtn.focus();

        teamDescriptionWrapper.addEventListener("focusout", () => {
          handleFocusOut(trigger);
        }, { once: true });

        teamDescriptionWrapper.addEventListener("keydown", (e) => {
          if (e.key === "Escape") {
            handleEscapeKey(trigger);
          }
        }, { once: true });

				// const teamName = info.querySelector('.team-name');
				closeModalBtn.addEventListener('click', () => {
					resetActive();
          trigger.focus();
				});

				activeItem = item;

				// scroll to active item
				const elementPosition = info.getBoundingClientRect().top;
				const offsetPosition = elementPosition - 150;
				window.scrollBy({
					top: offsetPosition,
					behavior: "smooth",
				});

			});
		});


	}

	window.addEventListener('resize', () => {
		items.forEach((item) => {
			const img = item.querySelector('.img-wrapper');
			if(img) {
				img.style.minWidth = '';
				img.style.width = '';
			}


		});

		init();
	});

	// trigger resize event to init
	window.dispatchEvent(new Event('resize'));
}
