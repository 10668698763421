import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export function blockRevealFullX() {
  gsap.registerPlugin(ScrollTrigger);

  const sections = document.querySelectorAll('[data-block-reveal-full-x]');

  const mediaQueryMobile = window.matchMedia('(max-width: 1023px)');
  const mediaQueryDesktop = window.matchMedia('(min-width: 1024px)');
  const borderRadius = getComputedStyle(document.documentElement).getPropertyValue('--site-borderRadius-block');

  let mm = gsap.matchMedia();

  if(window.matchMedia('(prefers-reduced-motion: reduce)').matches) return;

  sections.forEach((section) => {
    const background = document.querySelector('[data-block-reveal-full-x-background]');

    // Mobile - Tablet
    // --------------------------------------------
    mm.add(mediaQueryMobile, () => {
      gsap.to(background, {
        borderRadius: 0,
        ease: 'power2.inOut'
      });
    });

    // Desktop
    // --------------------------------------------
    mm.add(mediaQueryDesktop, () => {
      const tlUp = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: 'top center',
          end: 'top+=100px center',
          scrub: 1,
          invalidateOnRefresh: true,
          onRefresh: () => {
            tlUp.scrollTrigger.refresh();
          }
          // markers: {
          //   startColor: 'green',
          //   endColor: 'yellow',
          //   fontSize: '24px',
          //   fontWeight: 'bold',
          //   indent: 100
          // }
        }
      });

      const tlDown = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: 'bottom-=100px center',
          end: 'bottom center',
          scrub: 1,
          invalidateOnRefresh: true,
          onRefresh: () => {
            tlDown.scrollTrigger.refresh();
          }
          // markers: {
          //   startColor: 'orange',
          //   endColor: 'green',
          //   fontSize: '24px',
          //   fontWeight: 'bold',
          //   indent: 40
          // }
        }
      });

      tlDown.fromTo(
        background,
        {
          borderRadius: 0,
          scaleX: () => document.documentElement.clientWidth / section.offsetWidth
        },
        {
          borderRadius: borderRadius,
          scaleX: 1
        },
        0
      );

      tlUp.fromTo(
        background,
        {
          borderRadius: borderRadius,
          scaleX: 1
        },
        {
          borderRadius: 0,
          scaleX: () => document.documentElement.clientWidth / section.offsetWidth
        },
        0
      );
    });
  });
}
