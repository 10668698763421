import gsap from 'gsap';

export default function initMap() {
    const map = document.querySelector('#what-we-do-map');

    if (!map) {
        return;
    }

    const mapWrapper = map.querySelector('.map-wrapper');
    const mapInfos = map.querySelectorAll('.map__info');
    let animArr = [];
    let isActive = false;
    const mapPoints = map.querySelectorAll('svg path');

    if(window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      mapInfos.forEach((info) => {
        info.classList.add('active');
      });
      return;
    }

    function killAllAnimations() {
        animArr.forEach((anim) => {
            anim.kill();
        });
        animArr = [];

        Array.from(mapPoints).forEach((point) => {
            gsap.to(point, {
                fill: '#41748D',
            });
        });
		isActive = false;
    }

    function animMap(color) {
        if(mapWrapper.classList.contains('no-anim')) return;

        let delay = 0;

        const arr = Array.from(mapPoints);
        arr.forEach((point) => {
            const anim = gsap.to(point, {
                fill: '#41748D',
                duration: 1,
            });
            animArr.push(anim);
        });
        let rnd = [];
        const keys = arr.map((a,b)=>b);
        while(keys.length){
            rnd.push(arr[keys.splice(Math.floor(Math.random()*keys.length ),1)]);
        }
        // console.log(rnd.length, arr.length);
        rnd = rnd.splice(0, Math.round(rnd.length * 0.50));
        // console.log(rnd.length);

        rnd.forEach((point) => {
            gsap.set(point, {
                fill: '#41748D',
            });
            const anim = gsap.fromTo(point, {
                fill: '#41748D',
            },
            {
                fill: color,
                duration: Math.random() < 0.5 ? 1.2 : 0.8,
                delay: delay += 0.005
            },

            ).yoyo(true).repeat(-1);
            animArr.push(anim);
        });
	}

	function onScroll() {
		const br = mapWrapper.getBoundingClientRect();
		// console.log(br.top, br.bottom, window.innerHeight, isActive);
		if (br.top < window.innerHeight && br.bottom > 0 && !isActive) {
			console.log('AnimMap');
			animMap("#D9D9D6");
			isActive = true;
		}
		else if((br.top > window.innerHeight || br.bottom < 0) && isActive){
			console.log('KillAllAnimations');
			killAllAnimations();
			isActive = false;
		}
		if(br.top < window.innerHeight/2) {
			let time = 250;
			mapInfos.forEach((info) => {
				if(info.classList.contains('active')) return;
				setTimeout(() => {
					info.classList.add('active');
				}, time);
				time += 250;
			});
		}
	}

	function onResize() {
		if (window.innerWidth < 1024) {
            mapWrapper.classList.add('no-anim');
            killAllAnimations();
        } else {
            mapWrapper.classList.remove('no-anim');
			onScroll();
        }
	}

    window.addEventListener('scroll', () => {
        onScroll();
    });
	onScroll();

    window.addEventListener('resize', () => {
        onResize();
    });
	onResize();
}
