import Swiper from 'swiper';
import { Navigation, A11y } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/a11y';

import { getA11y } from '../../utils/swiper';

export function imageSimpleSlider() {
  const pageLang = document.querySelector("html").getAttribute("lang") || "en";
  const sliders = document.querySelectorAll('.swiper--images-simple');

  let sliderInstances = [];

  if (!sliders) return;

  // init
  // --------------------------------------------
  sliders.forEach((slider) => {
    const sliderSwiper = new Swiper(slider, {
      a11y: getA11y(pageLang),
      loop: false,
      spaceBetween: 12,
      slidesPerView: 1.5,
      centeredSlides: false,
      watchSlidesProgress: true,
      navigation: {
        nextEl: '.swiper-button-next-custom',
        prevEl: '.swiper-button-prev-custom'
      },
      breakpoints: {
        400: {
          slidesPerView: 1.5,
        },
        500: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 5,
        },
        1280: {
          slidesPerView: 6,
        }
      },
      modules: [Navigation, A11y],
    });
    sliderInstances.push(sliderSwiper);
  });
}
