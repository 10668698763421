import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export function imageFullWidthWithBox() {
  gsap.registerPlugin(ScrollTrigger);

  const blocks = document.querySelectorAll('[data-imageFullWidthWithBox]');

  if(window.matchMedia('(prefers-reduced-motion: reduce)').matches) return;

  if (blocks.length === 0) {
    return;
  }

  blocks.forEach((block) => {
    const content = block.querySelector('[data-imageFullWidthWithBox-content]');

    gsap.fromTo(
      content,
      {
        opacity: 0,
        y: 200
      },
      {
        scrollTrigger: {
          start: 'top top',
          end: () => {
            return `+=${document.documentElement.clientHeight * 1}`;
          },
          scrub: 1,
          trigger: block,
          pin: block,
          markers: false,
          invalidateOnRefresh: true,
        },
        opacity: 1,
        y: 0
      }
    );
  });
}
