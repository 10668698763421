import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

import { getDeviceInfo } from '../utils/device';

export default function initTimeline() {
	const timeline = document.querySelector('#timeline');

	if (!timeline) return;

	let currentYear = null;
	const timelineMobileYear = timeline.querySelector('.timeline-mobile-year');

	// detect if IOS device
	const deviceinfo = getDeviceInfo();
	// const isIOS = /iPad|iPhone|iPod|Macintosh|Mac/.test(navigator.userAgent) && navigator.maxTouchPoints > 1;
	// const devMessage = document.querySelector('#dev-message');
	// devMessage.innerHTML = navigator.userAgentData?.platform + ' / ' + navigator.maxTouchPoints + ' /  ' + isIOS + ' /  ' + navigator.userAgent;
	// console.log(navigator.userAgentData?.platform, navigator.maxTouchPoints, isIOS, navigator.userAgent);
	// console.log(devMessage);

	// if IOS device, add class to timeline
	if(!deviceinfo.isIOS) {
		timeline.classList.add('not-ios');
	}

	const timelineSwiper = new Swiper('#timeline .swiper', {
		loop: false,
		slidesPerView: 1.2,
		spaceBetween: 21,
		centeredSlides: true,
		autoHeight: true,
		// slidesPerGroup: 1,
		// loopFillGroupWithBlank: true,
		// pagination: {
		// 	el: '.swiper-pagination',
		// 	clickable: true,
		// },
		navigation: {
			nextEl: '.timeline-desktop-next',
			prevEl: '.timeline-desktop-prev',
		},
		breakpoints: {
			768: {
				slidesPerView: 'auto',
				spaceBetween: 42,
				autoHeight: false,
			}
		},
		modules: [Navigation],
	});

	const prevButton = document.querySelector('.timeline-mobile-prev');
	const nextButton = document.querySelector('.timeline-mobile-next');

	prevButton.addEventListener('click', () => {
		timelineSwiper.slidePrev();
	});

	nextButton.addEventListener('click', () => {
		timelineSwiper.slideNext();
	});

	timelineSwiper.on('slideChange', () => {

		const year = timelineSwiper.slides[timelineSwiper.activeIndex].dataset.year;
		timelineMobileYear.innerHTML = year;

		const yearTrigger = document.querySelector(`.timeline-year-trigger[data-year="${year}"]`);
		if(currentYear) {
			currentYear.classList.remove('active');
		}
		yearTrigger.classList.add('active');
		currentYear = yearTrigger;

	});

	const yearTriggers = document.querySelectorAll('.timeline-year-trigger');



	yearTriggers.forEach((trigger) => {
		trigger.addEventListener('click', () => {
			if(currentYear) {
				currentYear.classList.remove('active');
			}
 			const year = trigger.dataset.year;
			const yearSlide = document.querySelector(`#timeline .swiper-slide[data-year="${year}"]`);
			const index = yearSlide.dataset.swiperIndex;

			timelineSwiper.slideTo(index);
			trigger.classList.add('active');
			currentYear = trigger;
		});
	});


	currentYear = yearTriggers[0];
	currentYear.classList.add('active');


}
