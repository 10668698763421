export function initGtmCta() {
  const cta = document.querySelectorAll(
    '.btn-primary-icon:not(button[type="submit"]), .btn-primary-translate:not(button[type="submit"]'
  );

  cta.forEach((el) => {
    const href = el.getAttribute('href');
    const url = href.startsWith('#') ? window.location.href + href : href;

    el.addEventListener('click', () => {
      window.pushGTMEvent({
        event: 'user_interaction',
        event_name: 'cta_click',
        interaction: {
          link_url: url,
          label: el.getAttribute('aria-label')
        }
      });
    });
  });
}

export function formStartEvent(form) {
  const event = {
    event: 'user_interaction',
    event_name: 'form_start',
    interaction: {
      lead_type: form.getAttribute('data-lead-type')
    }
  };

  return event;
}

export function formSubmitEvent(form) {
  const event = {
    event: 'user_interaction',
    event_name: 'generate_lead',
    interaction: {
      lead_type: form.getAttribute('data-lead-type')
    }
  };

  return event;
}

export function formCompletedEvent(form) {
  const event = {
    event: 'user_interaction',
    event_name: 'form_submit',
    interaction: {
      lead_type: form.getAttribute('data-lead-type')
    }
  };

  return event;
}
