import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export function highlightCategories() {
  gsap.registerPlugin(ScrollTrigger);

  const sections = document.querySelectorAll('[data-highlightCategories]');

  let mm = gsap.matchMedia();
  const mediaQueryMobile = window.matchMedia('(max-width: 767px)');
  const mediaQueryTabletAndDesktop = window.matchMedia('(min-width: 768px)');

  if(window.matchMedia('(prefers-reduced-motion: reduce)').matches) return;

  // Desktop
  // --------------------------------------------
  sections.forEach((section) => {
    const heading = section.querySelector('[data-highlightCategories-heading]');
    const image = section.querySelector('[data-highlightCategories-image]');

    gsap.set(heading, { opacity: 0 });
    gsap.set(image, { opacity: 0 });

    // Mobile
    mm.add(mediaQueryMobile, () => {
      gsap
        .timeline({
          ease: 'power2.inOut',
          duration: 0.5,
          scrollTrigger: {
            trigger: section,
            start: 'top 100%',
            markers: false,
            once: false
          }
        })
        .fromTo(
          image,
          {
            autoAlpha: 0
          },
          {
            autoAlpha: 1
          }
        )
        .fromTo(
          heading,
          {
            autoAlpha: 0
          },
          {
            autoAlpha: 1
          },
          '-=50%'
        );
    });

    // Tablet and Desktop
    mm.add(mediaQueryTabletAndDesktop, () => {
      gsap
        .timeline({
          ease: 'power2.inOut',
          duration: 0.5,
          scrollTrigger: {
            trigger: section,
            start: 'top 100%',
            markers: false,
            once: false
          }
        })
        .fromTo(
          heading,
          {
            autoAlpha: 0,
            y: 50
          },
          {
            autoAlpha: 1,
            y: 0
          }
        )
        .fromTo(
          image,
          {
            autoAlpha: 0,
            y: 50
          },
          {
            autoAlpha: 1,
            y: 0
          },
          '-=50%'
        );
    });
  });
}
