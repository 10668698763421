export function validateField(field) {
	let isValid = true;

	if (field.value === '') {
		isValid = false;
		field.classList.add('error');
	} else if (field.type === 'email') {
		const re = /\S+@\S+\.\S+/;
		if (!re.test(field.value)) {
			isValid = false;
			field.classList.add('error');
		} else {
			field.classList.remove('error');
		}
	} else if (field.type === 'tel') {
		isValid = validatePhone(field);
  } else if (field.name === 'postal_code') {
		const re = /^[a-zA-Z]\d[a-zA-Z][ \-]?\d[a-zA-Z]\d$/;
		if (!re.test(field.value)) {
			isValid = false;
			field.classList.add('error');
		} else {
			field.classList.remove('error');
		}
	} else if (field.type === 'checkbox') {
		if (!field.checked) {
			isValid = false;
			field.classList.add('error');
		} else {
			field.classList.remove('error');
		}
	} else {
		field.classList.remove('error');
	}

  field.setAttribute('aria-invalid', isValid ? 'false' : 'true');

  if (!isValid) {
    field.closest('.input-control').classList.add('has-error');
  }
  else {
    field.closest('.input-control').classList.remove('has-error');
  }

	return isValid;
}

export function validateRequiredFields(form) {
	let isValid = true;
	const requiredFields = form.querySelectorAll('[required]');
	requiredFields.forEach((field) => {
		const valid = validateField(field);
		if(!valid) {
			isValid = false;
		}
	});
	return isValid;
}

export function validatePhone(field) {
	let isValid = true;
	const phone = field.value.replace(/\D/g, '');
	field.value = phone;
	if(phone.length === 0) return isValid;
	if (phone.length < 10) {
		field.classList.add('error');
		isValid = false;
	} else {
		field.classList.remove('error');
		field.value = formatPhone(phone);
	}

	return isValid;
}

export function formatPhone(phone) {
	if (phone.length === 10) {
		return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
	}
	if (phone.length === 11) {
		return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
	}
	return phone;
}

export function validatePhones(form) {
	let isValid = true;
	const phoneFields = form.querySelectorAll(`[type="tel"]`);

	phoneFields.forEach((field) => {
		const valid = validatePhone(field);
		if(!valid) {
			isValid = false;
		}
	});

	return isValid;
}
